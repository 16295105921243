<template>
  <div id="app">
    <ChatPanel />
  </div>
</template>

<script>
import ChatPanel from "./components/PanelChat.vue";
export default {
  name: "App",
  components: {
    ChatPanel,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
html {
  font-family: "DBHeavent";
  font-size: 20px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  line-height: 1.195;
}
@font-face {
  font-family: "DBHeavent";
  src: url("./assets/font/DB Heavent Cond v3.2.ttf");
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89,90,92, 0.25);
  outline: none;
}
</style>
