import {
  required,
  email,
  minLength,
  helpers,
  integer,
  maxLength
} from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[ก-๙a-zA-Z]*$/);

export default {
  preChatForm: {
    formTitle: "รบกวนกรอกข้อมูลด้านล่าง",
    formTitleEn: "Register live chat",
    field: {
      firstName: {
        label: "ชื่อ",
        labelEn: "First Name",
        fieldName: "firstName",
        type: "text",
        value: "",
        required: true,
      },
      lastName: {
        label: "นามสกุล",
        labelEn: "Last Name",
        fieldName: "lastName",
        type: "text",
        value: "",
        required: true,
      },
      email: {
        label: "อีเมล",
        labelEn: "Email",
        fieldName: "email",
        type: "email",
        value: "",
        required: true,
      },
      numberPhone: {
        label: "หมายเลขโทรศัพท์",
        labelEn: "Phone",
        fieldName: "numberPhone",
        type: "text",
        value: "",
        required: true,
      },
    },
    buttonTitle: "",
    validate: {
      firstName: { required, alpha },
      lastName: { required, alpha },
      email: { required, email },
      numberPhone: { required, minLength: minLength(9),maxLength: maxLength(10), integer },
    },
  },
  color: `${process.env.VUE_APP_PRIMARY_COLOR || '#fd7202'}`,
  secondaryColor: "rgb(253, 226, 211)",
  titleButton: "Livechat",
  title: "Live Chat",
  brandName: "Kerry",
  brandId: 1,
  platformName: "Web",
  platformId: 2,
  channelReferenceId: "WEB_KERRY",
  userId: "",
  agentIcon: "",
  agentName: "",
  userIcon: "",

  chatBaseUrl: `${process.env.VUE_APP_BASE_URL}`,
  apiKey: `${process.env.VUE_APP_API_KEY}`,
  chatBaseUrlSocket: `${process.env.VUE_APP_BASE_URL_SOCKET}`,
  apiKeyGoogleMap: `${process.env.VUE_APP_API_KEY_GOOGLE_MAP}`,
  socketKey: `${process.env.VUE_APP_SOCKET_KEY}`,
  sfRedirectUrl: `${process.env.VUE_APP_SF_REDIRECT_URL}`,
};
